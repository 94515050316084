.geo-popup {
    position: fixed; /* Stay in place */
    z-index: 10000; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.content-popup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin: auto;
    padding: 28px;
    border: 1px solid #888;
    width: 320px;
}

#country-list {
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
}

#country-list>div {
    display: flex;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 24px;
}

#country-list>div>a {
    display: flex;
    padding-left: 24px;
    padding-right: 24px;
    height: 48px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    color: #000;
    text-decoration: none;
    background: rgb(250, 250, 250)
    
}

#country-list>div>a:hover {
    background: rgb(230, 230, 230)
}

#country-list>div>a>img {
    width: 48px;
    height: 48px;
}

.exit-btn {
    background: #000;
    border: none;
    color: #fff;
    padding: 12px 24px 12px 24px;
    width: 100%;
    cursor: pointer;
}